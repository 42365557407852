import React, { Suspense, useState } from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";

const Loading = React.lazy(() => import("./pages/Loading/Loading"));
const Verify = React.lazy(() => import("./pages/Verify/Verify"));
const Error = React.lazy(() => import("./pages/Error/Error"));
const SharedLayout = React.lazy(() =>
  import("./pages/SharedLayout/SharedLayout")
);
const Policy = React.lazy(() => import("./pages/Policy/Policy"));


// SEAVIEW RESIDENCES
const TourSeaview = React.lazy(() =>
  import("./pages/Tour/TourSeaview")
);
const LocationSeaview = React.lazy(() =>
  import("./pages/Location/LocationSeaview")
);
const PlanSeaview = React.lazy(() => import("./pages/Plan/PlanSeaview"));
const UtilsSeaview = React.lazy(() =>
  import("./pages/Utilities/UtilitiesSeaview")
);
const HouseSeaview = React.lazy(() => import("./pages/House/HouseSeaview"));
const EBrochureSeaview = React.lazy(() =>
  import("./pages/EBrochure/EBrochureSeaview")
);

function App() {
  const languageState = useSelector((state) => state.language);
  const { locale, messages } = languageState;
  const [checkVerify, setCheckVerify] = useState(true);

  return (
    <IntlProvider messages={messages} locale={locale}>
      <div className="App">
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
            {checkVerify === true ? (
              <Routes>
                <Route path="/" index element={<Loading />} />
                <Route element={<SharedLayout />}>
                  {/* SEAVIEW RESIDENCES */}
                  <Route
                    index
                    path="/tour"
                    element={<TourSeaview />}
                  />
                  <Route
                    index
                    path="/location"
                    element={<LocationSeaview />}
                  />
                  <Route
                    index
                    path="/plan"
                    element={<PlanSeaview />}
                  />
                  <Route
                    index
                    path="/utils"
                    element={<UtilsSeaview />}
                  />
                  <Route
                    index
                    path="/house"
                    element={<HouseSeaview />}
                  />
                  <Route
                    index
                    path="/ebrochure"
                    element={<EBrochureSeaview />}
                  />

                  <Route
                    index
                    path="/chinh-sach-bao-mat"
                    element={<Policy />}
                  />
                  

                </Route>
                <Route path="*" element={<Error />} />
              </Routes>
            ) : (
              <Routes>
                <Route
                  path="*"
                  element={<Verify setCheckVerify={setCheckVerify} />}
                />
              </Routes>
            )}
            {/* <Routes>
              <Route path={"/"} index element={<Loading />} />
              <Route element={<SharedLayout />}>
                <Route index path="/verify" element={<Verify setCheckVerify={setCheckVerify} />} />
                <Route index path="/tour" element={<Tour />} />
                <Route index path="/location" element={<Location />} />
                <Route index path="/subdivision" element={<Subdivision />} />
                <Route index path="/gallery" element={<Gallery />} />

                <Route index path="/centralparkresidences/plan" element={<PlanCentralPark />} />
                <Route index path="/centralparkresidences/house" element={<HouseCentralPark />} />
                <Route index path="/centralparkresidences/utils" element={<UtilsCentralPark />} />
                <Route index path="/centralparkresidences/location" element={<LocationCentralPark />} />

                <Route index path="/thecampus/plan" element={<PlanTheCampus />} />
                <Route index path="/thecampus/utils" element={<UtilsTheCampus />} />
                <Route index path="/thecampus/location" element={<LocationTheCampus />} />
                <Route index path="/thecampus/ebrochure" element={<EBrochureTheCampus />} />

                <Route index path="/chinh-sach-bao-mat" element={<Policy />} />
              </Route>
              <Route path="*" element={<Error />} />
            </Routes> */}
          </Router>
        </Suspense>
      </div>
    </IntlProvider>
  );
}

export default App;
